<template>
    <div>

        <div class="detail-regalu" v-if="idRegalu > 0">
            <h2 class="text-center">{{ detailRegalu.nazev }}</h2>
            <div class="obecne-informace">
                <div class="row">
                    <div class="col-6">Pobočka</div>
                    <div class="col-6">{{ detailRegalu.pobocka }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Sklad</div>
                    <div class="col-6">{{ detailRegalu.sklad }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Název</div>
                    <div class="col-6">{{ detailRegalu.nazev }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Zkratka</div>
                    <div class="col-6">{{ detailRegalu.kod }}</div>
                </div>
            </div>
            <hr>
            <div class="pole">
                <div class="row">
                    <div class="col-6">Počet řad</div>
                    <div class="col-6">{{ detailRegalu.pocet_rad | formatNumber }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Počet sloupců</div>
                    <div class="col-6">{{ detailRegalu.pocet_sloupcu | formatNumber }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Celkem polí</div>
                    <div class="col-6">{{ detailRegalu.pocet_poli | formatNumber }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Celkem park. pozic</div>
                    <div class="col-6">{{ detailRegalu.pocet_pozic | formatNumber }}</div>
                </div>
            </div>
            <hr>
            <div class="zatizenost">
                <div class="row">
                    <div class="col-6">Nosnost sloupce</div>
                    <div class="col-6">{{ detailRegalu.nosnost_sloupce | formatNumber }} kg <span v-if="!detailRegalu.nosnost_sloupce > 0"> <small>(nezadáno)</small></span></div>
                </div>
                <div class="table-content top-20">
                    <div class="table-wrapper">
                        <table class="table table-bordered table-striped table-hover">
                            <thead>
                            <tr class="text-center">
                                <th>Sloupec</th>
                                <th>Obsazenost [kg]</th>
                                <th>Obsazenost [%]</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(zatizenost, id) in zatizenostRegalu">
                                <td class="text-center">{{ id }}</td>
                                <td class="text-right" :class="zatizenost.barva_textu">{{ zatizenost.obsazenost | formatNumber}} kg</td>
                                <td class="text-center" :class="zatizenost.barva_textu">{{ zatizenost.pomer }} %</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>
            <div class="top-20">
                <div class="table-content">
                    <div class="table-wrapper">
                        <p class="legenda">
                            <span class="text-bold">C - počet náhradních dílů celkem</span><br><span class="text-no-bold">N - nový náhradní díl</span> <br> <span class="text-success">P - použitý náhradní díl</span>
                        </p>
                        <table class="table table-bordered table-striped table-hover table-vertical">
                            <thead>
                            <tr class="text-center text-small">
                                <th>Sl.</th>
                                <th>Pozice</th>
                                <th>Náhradní díl</th>
                                <th class="td-table-nested text-center">
                                    <table class="table-nested">
                                        <tr>
                                            <td class="text-bold text-black border-left-0">C</td>
                                            <td class="">N</td>
                                            <td class="text-success border-right-0">P</td>
                                        </tr>
                                    </table>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="text-small">
                            <template v-for="(row, key) in skladoveZasoby">
                                <tr>
                                    <td class="text-center">{{ row.sloupec }}</td>
                                    <td class="text-center">
                                        <span v-html="$options.filters.detailParkovaciPozice(row.kod_pozice, row.id_pozice_regalu)"></span>
                                    </td>
                                    <td>
                                        <span v-html="$options.filters.detailPolozky(row.skladove_cislo, row.id_polozky)"></span> <br>
                                        <span v-html="$options.filters.detailPolozky(row.nazev_polozky, row.id_polozky, 35)"></span>
                                    </td>
                                    <td class="td-table-nested text-center">
                                        <table class="table-nested">
                                            <tr>
                                                <td class="text-bold text-black border-left-0">{{ row.pocet_mj_celkem | formatNumber }}  {{ row.merna_jednotka }}</td>
                                                <td class="">{{ row.pocet_mj_nove | formatNumber }}</td>
                                                <td class="text-success border-right-0">{{ row.pocet_mj_pouzite | formatNumber }}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

        </div>
        <div class="detail-regalu" v-else>
            <h2 class="text-danger text-center">Není možné zobrazit detail regálu u nezaparkovaných položek</h2>
        </div>

    </div>
</template>

<script>
import axiosApiPostV2 from "@/router/axiosApiMethod";

export default {
    name: "Regal",
    data() {
        return {
            idRegalu: this.$route.params.id_regalu,
            detailRegalu: {},
            zatizenostRegalu: {},
            skladoveZasoby: {}
        }
    },
    created() {
        if (this.idRegalu != 'N' && this.idRegalu != 0)
        {
            let params = {
                id_regalu: this.idRegalu,
            };
            this.$root.$emit('showLoader', {show: true});
            axiosApiPostV2('detail-regalu', params, 'detail').then( result => {
                this.detailRegalu = result;
                let nosnostSloupce = result.nosnost_sloupce;
                axiosApiPostV2('regaly-obsazenost', {typ: 'regal', id: this.idRegalu}, 'data').then( result => {
                    $.each(result, function (key, value) {
                        let barvaTextu = '';
                        if (value.pomer >= 90)
                        {
                            barvaTextu = 'text-bold';
                        }
                        if (value.pomer == 100 && value.obsazenost < nosnostSloupce)
                        {
                            value.pomer = 99;
                        }
                        if (value.pomer > 100 || value.obsazenost > nosnostSloupce)
                        {
                            barvaTextu = 'text-danger';
                        }
                        value.barva_textu = barvaTextu;
                    });
                    this.zatizenostRegalu = result;

                    axiosApiPostV2('skladove-zasoby-parkovaci-pozice', {id_regalu: this.idRegalu, detail_polozky: true}, 'data').then( result => {

                        $.each(result, function (key, value) {
                            value.pocet_mj_celkem = value.pocet_mj_nove + value.pocet_mj_pouzite;
                            let sloupce = value.kod_pozice.split('.');
                            value.sloupec = sloupce[1];
                        });
                        this.skladoveZasoby = result;
                        this.$root.$emit('showLoader', {show: false});
                    });
                });
            });
        }
    },
    methods: {
        routeToDetail(idPoziceRegalu)
        {
            this.$root.$emit('route', {page: '/detail/parkovaci-pozice/' + idPoziceRegalu});
        }
    },
    filters: {
        maxLength(value, max = 35)
        {
            return Helper.maxLength(value, max);
        },
        formatNumber(value) {
            return Helper.formatNumber(value);
        },
        decimalNumber(value) {
            return Helper.decimalNumber(value);
        },
        detailPolozky(value, idPolozky, max = 0)
        {
            return Helper.detailPolozky(value, idPolozky, max);
        },
        detailParkovaciPozice(value, idParkovaciPozice)
        {
            return Helper.detailParkovaciPozice(value, idParkovaciPozice);
        }
    }
}
</script>

<style lang="scss" scoped>
    .table-striped-two-rows {
        /*
        tr:nth-child(3n) {
            background: rgba(0,0,0,.05);;
        }
        tr:nth-child(4n) {
            background: rgba(0,0,0,.05);;
        }

         */
        .row-bck-pocet:nth-child(4n)
        {
            background: rgba(0,0,0,.05);
        }
        .row-bck:nth-child(3n), .row-bck:nth-child(7n)
        {
            background: rgba(0,0,0,.05);
        }


    }
    .legenda {
        font-size: 0.7em;
    }
    .text-no-bold {
        font-weight: normal;
    }

    .table-striped tbody .table-nested tr{
        background-color: transparent;
    }

    .table td.td-table-nested {
        padding: 0px;
    }

    .table th.td-table-nested {
        padding: 0px;
    }

    .table td.td-table-nested .table-nested {
        height: 43px;
    }

    .table td.td-table-nested .table-nested td{
        width: 10px;
        text-align: center;
    }

    .table th.td-table-nested .table-nested td{
        width: 100%;
        text-align: center;
    }

    .td-table-nested {
        td, th, td {
            border-top: none;
            border-bottom: none;
        }
    }

    .table-nested {
        width: 100%;

        padding: 0px;
        margin: 0px;
        tr, td, th {
            background: transparent;

        }
    }

</style>